import React, { Suspense, lazy } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { ConnectedRouter } from 'connected-react-router';
import ErrorBoundaryRoute from '../shared/error/ErrorBoundaryRoute';
import IndexLayout from '../layouts';
import NotFoundPage from '../shared/error/PageNotFound';
import PageLoader from '../shared/loader/pageLoader';

const LoginContainer = lazy(() => import('../modules/auth/container'));
const PorfolioContainer = lazy(() => import('../modules/portfolios/index'));

const HomeContainer = lazy(() => import('../modules/home/container'));
const CreatePostContainer = lazy(() => import('../modules/create-post/container'));
const EditPostContainer = lazy(() => import('../modules/edit-post/container'));
const ProfileContainer = lazy(() => import('../modules/profile/container'));
const LogoutContainer = lazy(() => import('../modules/logout/container'));
const PostContainer = lazy(() => import('../modules/post/container'));
const UnaccessibleContainer = lazy(() => import('../modules/unaccessible/container'));
const CreatePortfolio = lazy(() => import('../modules/CreatePortfolio/container'));
const EditPortfolio = lazy(() => import('../modules/EditPortfolio/container'));
const appWebRoutes = [
  {
    path: '/',
    component: HomeContainer,
    exact: true,
  },
  {
    path: '/unaccessible',
    component: UnaccessibleContainer,
    exact: true,
  },
  {
    path: '/create-post',
    component: CreatePostContainer,
    exact: true,
  },
  {
    path: '/edit-post/:communityID/:messageID',
    component: EditPostContainer,
    exact: true,
  },
  {
    path: '/research',
    component: HomeContainer,
    exact: true,
  },
  {
    path: '/auth/login',
    component: LoginContainer,
    exact: true,
  },
  {
    path: '/portfolio/:portfolioID',
    component: PorfolioContainer,
    exact: true,
  },
  {
    path: '/EditPortfolio/:portfolioID',
    component: EditPortfolio,
    exact: true,
  },
  {
    path: '/profile/:profileName',
    component: ProfileContainer,
    exact: true,
  },
  {
    path: '/post/:communityID/:messageID',
    component: PostContainer,
    exact: true,
  },
  {
    path: '/auth/logout',
    component: LogoutContainer,
    exact: true,
  },
  {
    path: '/create-portfolio',
    component: CreatePortfolio,
    exact: true,
  },
];

const appMobileRoutes = [
  {
    path: '/',
    component: HomeContainer,
    exact: true,
  },
  {
    path: '/auth/login',
    component: LoginContainer,
    exact: true,
  },
  {
    path: '/research',
    component: HomeContainer,
    exact: true,
  },
  {
    path: '/profile/:profileName',
    component: ProfileContainer,
    exact: true,
  },
  {
    path: '/EditPortfolio/:portfolioID',
    component: EditPortfolio,
    exact: true,
  },
  {
    path: '/post/:communityID/:messageID',
    component: PostContainer,
    exact: true,
  },
  {
    path: '/portfolio/:portfolioID',
    component: PorfolioContainer,
    exact: true,
  },
  {
    path: '/auth/logout',
    component: LogoutContainer,
    exact: true,
  },
  {
    path: '/create-post',
    component: CreatePostContainer,
    exact: true,
  },
  {
    path: '/edit-post/:communityID/:messageID',
    component: EditPostContainer,
    exact: true,
  },
  {
    path: '/create-portfolio',
    component: CreatePortfolio,
    exact: true,
  },
];

const Router = ({ history }) => {
  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <ConnectedRouter history={history}>
      <Suspense fallback={<PageLoader />}>
        <IndexLayout>
          <Switch>
            {!mobileScreen
              && appWebRoutes.map(route => (
                <ErrorBoundaryRoute
                  path={route.path}
                  component={route.component}
                  key={route.path}
                  exact={route.exact}
                />
              ))}
            {mobileScreen
              && appMobileRoutes.map(route => (
                <ErrorBoundaryRoute
                  path={route.path}
                  component={route.component}
                  key={route.path}
                  exact={route.exact}
                />
              ))}
            <Route component={NotFoundPage} />
          </Switch>
        </IndexLayout>
      </Suspense>
    </ConnectedRouter>
  );
};

Router.propTypes = {
  history: PropTypes.instanceOf(Object),
};

Router.defaultProps = {
  history: {},
};

export default React.memo(Router);
