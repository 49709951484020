import gql from 'graphql-tag';

export const GET_STOCK_INFO_QUERY = gql`
  query getFeaturedEquities($userID: ID, $offset: Int, $limit: Int) {
    getFeaturedEquities(userID: $userID, offset: $offset, limit: $limit) {
      nifty {
        ltp
        volume
        prevClose
      }
      sensex {
        ltp
        volume
        prevClose
      }
    }
  }
`;

export const GET_FEATURED_EQUITIES_QUERY = gql`
  query getFeaturedEquities(
    $userID: ID
    $search: String
    $offset: Int
    $limit: Int
  ) {
    getFeaturedEquities(
      userID: $userID
      search: $search
      offset: $offset
      limit: $limit
    ) {
      strikeRate
      rsi
      total
      link
      nifty {
        date
        ltp
        volume
        prevClose
        prevVolume
      }
      sensex {
        date
        ltp
        volume
        prevClose
        prevVolume
      }
      equities {
        symbol
        company
        entryPrice
        quantity
        targetPrice
        stopLoss
        ltp
        ltpPercent
        cumulativeRsi
        link
        ord
        prevClose
        message {
          ID
          communityID
          messageType
          createdOn
          community {
            title
          }
          creator {
            ID
            username
            fullname
            profilePic
            followedByMe
            followStatus
          }
          status
          content
          statistics {
            isLikedSelf
            views
            likes
            shares
            delivers
            threadReplies
          }
          __typename
        }
      }
    }
  }
`;

export const GET_FEATURED_EQUITY_QUERY = gql`
  query getFeaturedEquity($search: String!) {
    getFeaturedEquities(search: $search) {
      symbol
      company
      entryPrice
      quantity
      targetPrice
      stopLoss
      ltp
      ltpPercent
      cumulativeRsi
      link
      ord
    }
  }
`;

export const GET_EQUITY_QUERY = gql`
  query getEquity($symbol: String!, $onlyActive: Boolean, $se: String) {
    getEquity(symbol: $symbol, onlyActive: $onlyActive, se: $se) {
      symbol
      status
      isActive
      bse
      nse
      company
      industry
      isin
      faceValue
      lastQuote {
        ltp
        change
        changePercent
        volume
        dividend
        roe
        marketCap
        w52low
        w52high
      }
    }
  }
`;

export const SEARCH_EQUITIES_QUERY = gql`
  query globalSearch($text: String!, $limit: Int, $offset: Int) {
    globalSearch(text: $text) {
      equities(offset: $offset, limit: $limit) {
        equity {
          symbol
          company
          lastQuote {
            ltp
            change
            changePercent
            previousClose
            high
            low
            open
            volume
          }
        }
        search {
          searchedText
          foundText
          score
          total
        }
      }
    }
  }
`;

export const GET_WHATCHLIST_FEATURED_EQUITIES_QUERY = gql`
  query(
    $showSold: Boolean
    $search: String
    $se: String
    $offset: Int
    $limit: Int
  ) {
    getMyFeaturedEquities(
      showSold: $showSold
      se: $se
      offset: $offset
      limit: $limit
    ) {
      equities {
        symbol
        company
        entryPrice
        quantity
        targetPrice
        stopLoss
        ltp
        ltpPercent
        link
        ord
        prevClose
      }
    }
    getFeaturedEquities(search: $search, offset: $offset, limit: $limit) {
      nifty {
        date
        ltp
        volume
        prevClose
        prevVolume
      }
      sensex {
        date
        ltp
        volume
        prevClose
        prevVolume
      }
      equities {
        symbol
        company
        entryPrice
        quantity
        targetPrice
        stopLoss
        ltp
        ltpPercent
        cumulativeRsi
        link
        ord
        prevClose
        message {
          ID
          communityID
          messageType
          createdOn
          community {
            title
          }
          creator {
            ID
            username
            fullname
            profilePic
            followedByMe
            followStatus
          }
          status
          content
          statistics {
            isLikedSelf
            views
            likes
            shares
            delivers
            threadReplies
          }
          __typename
        }
      }
    }
  }
`;

export const GET_MY_FEATURED_EQUITIES_QUERY = gql`
  query getMyFeaturedEquities(
    $showSold: Boolean
    $search: String
    $se: String
    $offset: Int
    $limit: Int
  ) {
    getMyFeaturedEquities(
      showSold: $showSold
      search: $search
      se: $se
      offset: $offset
      limit: $limit
    ) {
      equities {
        symbol
        company
        entryPrice
        targetPrice
        targetPricePercent
      }
    }
  }
`;

export const ADD_MY_FEATURED_EQUITY = gql`
  mutation addMyFeaturedEquity(
    $symbol: String!
    $data: FeaturedEquityData
    $ord: Int
  ) {
    addMyFeaturedEquity(symbol: $symbol, data: $data, ord: $ord)
  }
`;

export const DELETE_MY_FEATURED_EQUITY = gql`
  mutation deleteMyFeaturedEquity($symbol: String!) {
    deleteMyFeaturedEquity(symbol: $symbol)
  }
`;

export const ADD_FEATURED_EQUITY = gql`
  mutation addFeaturedEquity(
    $userID: ID
    $symbol: String!
    $data: FeaturedEquityData!
    $ord: Int
  ) {
    addFeaturedEquity(userID: $userID, symbol: $symbol, data: $data, ord: $ord)
  }
`;

export const GET_STOCK_VALUES = gql`
  {
    stockValues @client {
      nifty {
        ltp
        volume
        prevClose
        prevVolume
      }
      sensex {
        ltp
        volume
        prevClose
        prevVolume
      }
    }
  }
`;

export const GET_PORTFOLIO_STOCKS = gql`
  query getPortfolioStocks($portfolioId: ID){
    getPortfolio(ID: $portfolioId){
      ID
      name
      draftSet{
        ID
        status
        startedAt
        constituents{
          minAmount
          items{
            ID
            symbol
            weight
            shares
            sharesPrice
            lastQuote{
              ltp
            }
            price
          }
        }
      }
      activeSet{
        ID
        status
        startedAt
        constituents{
          minAmount
          items{
            ID
            symbol
            weight
            shares
            sharesPrice
            lastQuote{
              ltp
            }
            price
          }
        }
      }
    }
  }
`;

export const GET_PORTFOLIO_PERFORMACE = gql`
query getPortfolio($period: String!, $index: String, $portfolioId: ID!) {
  getPortfolio(ID: $portfolioId){
    performance(period: $period, index: $index) {
      portfolioPoints {
        time
        value
      }
      benchmarkPoints {
        time
        value
      }
    }
  }
   
}
`;

export const UPDATE_STOCK_VALUES = gql`
  mutation updateStockValue($stockValues: stockValues!) {
    updateStockValue(stockValues: $stockValues) @client
  }
`;

export const PUBLISH_PORTFOLIO_SET = gql`
  mutation publishPortfolioSet($portfolioId: ID!){
    publishPortfolioSet(ID: $portfolioId){
      ID
    }
  }
`;

export const ADD_PORTFOLIO_ITEM = gql`
  mutation addPortfolioItem($portfolioSetID: ID!, $symbol: ID!, $weight: Float!){
    addPortfolioItem(portfolioSetID: $portfolioSetID, symbol: $symbol, weight: $weight){
      ID
      symbol
    }
  }
`;

export const UPDATE_PORTFOLIO_ITEM = gql`
  mutation updatePortfolioItem($portfolioItemID: ID!, $symbol: ID!, $weight: Float){
    updatePortfolioItem(portfolioItemID: $portfolioItemID, symbol: $symbol, weight: $weight){
      ID
      symbol
    }
  }
`;

export const DELETE_PORTFOLIO_ITEM = gql`
  mutation deletePortfolioItem($portfolioItemID: ID!){
    deletePortfolioItem(portfolioItemID: $portfolioItemID)
  }
`;


// const portfolioItem = gql`
//   type PortfolioItem {
//     symbol: String!
//     weight: Float
//   }
// `;

export const UPDATE_PORTFOLIO_SET = gql`
  mutation updatePortfolioSet($portfolioSetID: ID!, $portfolioItems: [PortfolioItemData!], $createdBy: String){
    updatePortfolioSet(portfolioSetID: $portfolioSetID, portfolioItems: $portfolioItems, createdBy: $createdBy)
  }
`;


export const MAKE_PORTFOLIO_SET = gql`
  mutation makePortfolioSet($portfolioID: ID!, $createdBy: String){
    makePortfolioSet(portfolioID: $portfolioID, createdBy: $createdBy){
      ID
    }
  }
`;

export const GET_SMALLCASE_RISK_LIST = gql`
query {
  getSmallcaseRiskList {
    riskLevel
    portfolios {
      scid
      tmid
    } 
  }
}
`;

export const GET_PORTFOLIO_RISK_LIST_V2 = gql`
query getPortfoliosV2($userID: ID, $fetchAll: Boolean) {
    getPortfoliosV2(userID:$userID, fetchAll: $fetchAll) {
      portfolioID
      portfolioName
      scid
      riskLevels
  }
}
`;


export const GET_PORTFOLIO_BY_ID = gql`
query getPortfoliosV2($userID: ID, $fetchAll: Boolean) {
    getPortfoliosV2(userID:$userID, fetchAll: $fetchAll) {
    portfolioID
    portfolioName
    scid
    benchmarkId
    riskLevels
    cagr
    iconType
    cardType
    minInvestAmount
    riskLevels
    portfolioDescription
    isDefault
  }
}
`;

// export const DELETE_PORTFOLIO = gql`
//   mutation deletePortfolio($portfolioItemID: ID!){
//     deletePortfolio(portfolioItemID: $portfolioItemID)
//   }
// )`

    export const CREATE_NEW_PORTFOLIO = gql`
  mutation createNewPortfolio(
        $portfolioNameInEnglish: String!,
        $portfolioDescriptionInEnglish: String!,
        $portfolioNameInHindi: String!,
        $portfolioDescriptionInHindi: String!,
        $portfolioLogo:String!,
        $scid: String!,
        $cardType: String!, 
        $iconType: String!,
        $dependsOnPortfolio: String!,
        $benchmarkID: String!,
        $isDefault: Boolean,
        $riskLevelText: [String!]!,
        $riskLevels: [String!]!
      ) {
    createNewPortfolio(
        portfolioNameInEnglish: $portfolioNameInEnglish,
        portfolioDescriptionInEnglish: $portfolioDescriptionInEnglish,
        portfolioNameInHindi: $portfolioNameInHindi,
        portfolioDescriptionInHindi: $portfolioDescriptionInHindi,
        portfolioLogo: $portfolioLogo,
        scid: $scid,
        cardType: $cardType,
        iconType: $iconType,
        dependsOnPortfolio: $dependsOnPortfolio,
        benchmarkID: $benchmarkID,
        isDefault: $isDefault,
        riskLevelText: $riskLevelText,
        riskLevels: $riskLevels
      )
  }
`;

export const UPDATE_NEW_PORTFOLIO = gql`
  mutation editNewPortfolio(
    $portfolioID:Int!,
    $portfolioNameInEnglish:String!,
    $portfolioDescriptionInEnglish:String!,
    $portfolioNameInHindi:String!,
    $portfolioDescriptionInHindi:String!,
    $portfolioLogo:String!,
    $scid:String!,
    $isDefault:Boolean,
    $isDeleted:String!,
    $cardType:String!, 
    $iconType:String!, 
    $riskLevelText:[String!]!,
    $riskLevels:[String!]!,
    $dependsOnPortfolio:String!,
    $benchmarkID:String!
  ) {
    editNewPortfolio(
        portfolioID: $portfolioID,
        portfolioNameInEnglish: $portfolioNameInEnglish,
        portfolioDescriptionInEnglish: $portfolioDescriptionInEnglish,
        portfolioNameInHindi: $portfolioNameInHindi,
        portfolioDescriptionInHindi: $portfolioDescriptionInHindi,
        portfolioLogo: $portfolioLogo,
        scid: $scid,
        isDefault: $isDefault,
        isDeleted: $isDeleted,
        cardType: $cardType,
        iconType: $iconType,
        riskLevelText: $riskLevelText,
        riskLevels: $riskLevels,
        dependsOnPortfolio: $dependsOnPortfolio,
        benchmarkID: $benchmarkID
      )
  }
`;


export const GET_PORTFOLIO_DETAILS_BY_ID = gql`
query getPortfolioDetailsByID($portfolioID:ID){
  getPortfolioDetailsByID(portfolioID:$portfolioID){
    portfolioID
    status
		portfolioNameInEnglish
    portfolioDescriptionInEnglish
    portfolioNameInHindi
    portfolioDescriptionInHindi
    portfolioLogo
    scid
   minInvestAmount
    cardType
    iconType
    riskLevelText
    dependsOnPortfolio
    benchmarkID
    isDeleted
  }
}`;

export const FETCH_PORTFOLIO_TAGS = gql`
query fetchPortfolioTags($id: Int!, $tagname: [String]!){
	fetchPortfolioTags(portfolioID: $id, tagNames: $tagname){
    portfolioID
    tagName
    tagValue
  }
}`;

export const GET_ACTIVE_PORTFOLIO_SET_BY_PORTFOLIOID = gql`
query getActivePortfolioSetByPortfolioID($portfolioID:ID){
  getActivePortfolioSetByPortfolioID(portfolioID: $portfolioID){
      ID
      portfolioID
      createdBy
      createdOn
      fullname
      lastUpdated
      startedAt
      status
    }
  }
`;

export const GET_USER = gql`
query getUser($ID: ID!) {
  getUser(ID: $ID) {
    fullname
  }
}
`;

