import React from 'react';
import PropTypes from 'prop-types';
import split from 'lodash/split';
import size from 'lodash/size';
import { useLocation } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Authorize from '../../shared/authorize';
import { useWindowSize } from '../../hooks';
import useStyle from './styles';

const Header = React.lazy(() => import('../../shared/header'));
const BottomNavigation = React.lazy(() => import('../../shared/drawers/bottomNavigation'));
const LeftNavigation = React.lazy(() => import('../../shared/drawers/leftNavigation'));

const MessageLayout = ({ children }) => {
  const classes = useStyle();
  const { pathname } = useLocation();
  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMobileMessage = size(split(pathname, '/')) === 3 || size(split(pathname, '/')) === 4;
  const isMobileHide = mobileScreen && isMobileMessage;
  const windowSize = useWindowSize();
  return (
    <Authorize redirect>
      <div className={classes.root}>
        {!isMobileHide && <Header />}
        <div className={classes.container} style={{ minHeight: windowSize.height }}>
          <LeftNavigation />
          <main className={classes.contentContainer}>
            <div className={classes.content}>
              {!isMobileHide && <div className={classes.topToolbar} />}
              <div className={classes.contentWrapper}>
                <div className={classes.childrenContainer}>{children}</div>
              </div>
              {!isMobileHide && <div className={classes.bottomToolbar} />}
            </div>
          </main>
        </div>
        {!isMobileHide && <BottomNavigation />}
      </div>
    </Authorize>
  );
};

MessageLayout.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired
};

export default React.memo(MessageLayout);
