const actions = {
  SET_STATE: 'message/SET_STATE',
  INIT_SOCKET_CONNECTION: 'message/INIT_SOCKET_CONNECTION',
  GET_MESSAGE_VALUES: 'message/GET_MESSAGE_VALUES',
  ADD_MESSAGE_VALUE: 'message/ADD_MESSAGE_VALUE',
  SET_MESSAGE_VALUE: 'message/SET_MESSAGE_VALUE',
  SET_EDIT_MESSAGE: 'message/SET_EDIT_MESSAGE',
  UPDATE_MESSAGE_VALUE: 'message/UPDATE_MESSAGE_VALUE',
  UPDATE_MESSAGE_ACTION: 'message/UPDATE_MESSAGE_ACTION',
  UPDATE_USER_CALLBACK: 'message/UPDATE_USER_CALLBACK'
};

export default actions;
