import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  formContainer: {
    flex: 1,
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '20px 5px',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      margin: '5px 5px 10px',
    },
  },
  button: {
    borderWidth: 2,
    borderRadius: 10,
    fontWeight: 700,
    padding: '5px 45px',
    fontSize: 18,
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  paragraphContainer: {
    textAlign: 'center',
    paddingTop: 20,
    paddingBottom: 20
  },
  authButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingTop: 10,
    paddingBottom: 10
  },
  authGoogleButton: {
    backgroundColor: '#4284f5',
    color: '#fff',
    paddingLeft: 0,
    paddingBottom: 0,
    paddingTop: 0,
    textTransform: 'inherit',
    '&:hover,&:focus,&:active': {
      backgroundColor: '#4284f5',
      color: '#fff'
    }
  },
  labelText: {
    paddingBottom: 20,
  },
  startIcon: {
    marginLeft: 0,
    '& > *:first-child': {
      fontSize: 40
    }
  },
}));
