import gql from 'graphql-tag';

export const GET_AUTO_ADMIN = gql`
  query getAutoAdmins($type: Int, $status: Int) {
    getAutoAdmins(type: $type, status: $status) {
      user {
        ID
        userType
      }
      userType
      status
    }
  }
`;

export const ADD_AUTO_ADMIN = gql`
  mutation addAutoAdmin($userID: ID!, $type: Int, $status: Int) {
    addAutoAdmin(userID: $userID, type: $type, status: $status)
  }
`;

export const APPLY_AUTO_ADMINS = gql`
  mutation applyAutoAdmins {
    applyAutoAdmins
  }
`;
