export default {
  loading: false,
  loadingCommunity: false,
  isConnected: true,
  isReplyMessage: false,
  activeModalName: '',
  activeModalValue: null,
  postPreviewValue: null,
  orderValue: JSON.stringify({}),
  searchValues: null,
  phoneNumber: null,
  messages: [],
  message: null,
  isEmptyCommunities: true,
  communities: [],
  getCommunity: null,
  selectedCommunityName: null,
  selectedCommunity: 0,
  hasNextMessage: false,
  hasNextPageMessage: true,
  unreadMessages: [],
  unreadMessage: 0,
  countMessages: 0,
  downMessageButton: false,
  stockValues: {
    nifty: {
      ltp: '0',
      volume: '0.0',
      prevClose: '0',
      prevVolume: '0',
      __typename: 'niftyValues'
    },
    sensex: {
      ltp: '0',
      volume: '0.0',
      prevClose: '0',
      prevVolume: '0',
      __typename: 'sensexValues'
    },
    __typename: 'stockValues'
  }
};
