import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import useStyle from './styles';

const Header = React.lazy(() => import('../../shared/header'));
const BottomNavigation = React.lazy(() => import('../../shared/drawers/bottomNavigation'));
const LeftNavigation = React.lazy(() => import('../../shared/drawers/leftNavigation'));

const TwitterLayout = ({ children }) => {
  const classes = useStyle();
  return (
    <div className={classes.root}>
      <Header />
      <div className={classes.container}>
        <LeftNavigation />
        <main className={classes.contentContainer}>
          <div className={classes.content}>
            <div className={classes.topToolbar} />
            <div className={classes.contentWrapper}>
              <div className={classes.childrenContainer}>
                {children}
              </div>
            </div>
            <div className={classes.bottomToolbar} />
          </div>
        </main>
      </div>
      <BottomNavigation />
    </div>
  );
};

TwitterLayout.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired
};

export default React.memo(withRouter(TwitterLayout));
