import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import {
  Button, Box, MenuItem, CircularProgress
} from '@material-ui/core';
import TextInput from '../../inputs/textInput';
import TextSelect from '../../inputs/textSelect';
import GoogleProvider from './googleProvider';
import useStyles from './styles';
import { required, phoneNumber } from '../../../utils/formValidation';
import { countries } from '../../../utils/data.json';

const AuthForm = ({ submitting, handleSubmit, onSubmit }) => {
  const classes = useStyles();
  return (
    <Box className={classes.formContainer}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.fields}>
          <TextSelect
            label="Country"
            name="countryCode"
            validate={[required]}
          >
            {countries.map(option => (
              <MenuItem key={option.label} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextSelect>
          <TextInput
            label="Phone Number"
            name="phoneNumber"
            type="tel"
            validate={[required, phoneNumber]}
            variant="outlined"
            fullWidth
            margin="normal"
          />
        </div>
        <GoogleProvider />
        <div className={classes.buttonContainer}>
          <Button
            id="sign-in-button"
            variant="outlined"
            type="submit"
            size="large"
            color="primary"
            disabled={submitting}
            classes={{ root: classes.button }}
          >
            Next
          </Button>
          {submitting && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
      </form>
    </Box>
  );
};

AuthForm.propTypes = {
  handleSubmit: PropTypes.func,
  onSubmit: PropTypes.func,
  submitting: PropTypes.bool,
};

AuthForm.defaultProps = {
  handleSubmit: () => {},
  onSubmit: () => {},
  submitting: false,
};

export default reduxForm({
  form: 'loginForm',
  initialValues: {
    countryCode: '91',
  },
})(memo(AuthForm));
