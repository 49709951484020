import { connect } from 'react-redux';
import React, {
  forwardRef, useRef, memo, useCallback, useEffect
} from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Button,
  Slide,
  CircularProgress
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CloseIcon from '@material-ui/icons/Close';
import PhoneNumber from './phoneNumber';
import OTPInput, { ResendOTP } from './OTP';
import useStyles from './styles';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const VerificationCode = memo(
  ({
    loading, error, success, activeModal, messageSent, onClose, dispatch
  }) => {
    const credentialRef = useRef(null);
    const phoneNumberRef = useRef(null);
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    let props = {
      fullScreen,
      fullWidth: true,
      open: activeModal
    };
    if (fullScreen) {
      props = Object.assign({}, props, {
        TransitionComponent: Transition
      });
    }

    const handleChange = useCallback((values) => {
      credentialRef.current = values;
    }, []);

    const handleSubmit = useCallback(
      (event) => {
        event.preventDefault();
        dispatch({
          type: 'user/VERIFY',
          payload: String(credentialRef.current)
        });
      },
      [dispatch]
    );

    const handleResend = useCallback(() => {
      dispatch({
        type: 'user/SEND_OTP_CODE',
        payload: {
          phoneNumber: phoneNumberRef.current,
        }
      });
    }, [dispatch]);

    const handleClose = useCallback(() => {
      onClose();
      dispatch({
        type: 'user/CLEAR_RECAPTCHA',
      });
    }, [onClose]);

    useEffect(() => {
      if (error || success) {
        handleClose();
      }
    }, [error, success]);
    return (
      <Dialog {...props}>
        {fullScreen && (
          <DialogTitle classes={{ root: classes.title }}>
            <IconButton
              aria-label="Close"
              className={classes.closeButton}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
        )}
        <DialogContent className={classes.contentContainer}>
          <PhoneNumber phoneNumberRef={phoneNumberRef} />
          <div className={classes.formContainer}>
            <form onSubmit={handleSubmit}>
              <div className={classes.fieldContainer}>
                <OTPInput onChange={handleChange} />
                <div className={classes.buttonContainer}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={!messageSent || loading}
                  >
                    Verify
                  </Button>
                  {loading && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </div>
              </div>
            </form>
          </div>
          <ResendOTP onClose={handleClose} onResendClick={handleResend} />
        </DialogContent>
      </Dialog>
    );
  }
);

VerificationCode.propTypes = {
  loading: PropTypes.bool,
  messageSent: PropTypes.bool,
  error: PropTypes.bool,
  success: PropTypes.bool,
  activeModal: PropTypes.bool,
  onClose: PropTypes.func,
  dispatch: PropTypes.func
};

VerificationCode.defaultProps = {
  loading: false,
  error: false,
  success: false,
  messageSent: false,
  activeModal: false,
  onClose: () => {},
  dispatch: () => {}
};

const mapStateToProps = state => ({
  loading: state.auth.loading,
  error: state.auth.error,
  success: state.auth.success,
  messageSent: state.auth.messageSent
});

const mapDispatchToProps = dispatch => ({
  dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(VerificationCode);
