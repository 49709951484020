import classname from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import { defaultInitials } from '../../utils';
import useStyle from './styles';

const Component = ({
  childern, onClick, name, small, large, medium, ...rest
}) => {
  const classes = useStyle({ name });
  return (
    <>
      <Avatar
        onClick={onClick}
        classes={{
          root: classes.root,
          colorDefault: classes.colorDefault
        }}
        className={classname({
          [classes.small]: small,
          [classes.large]: large,
          [classes.medium]: medium
        })}
        {...rest}
      >
        {/* {defaultInitials(name, 1)} */}
      </Avatar>
    </>
  );
};

Component.propTypes = {
  name: PropTypes.string,
  small: PropTypes.bool,
  large: PropTypes.bool,
  medium: PropTypes.bool,
  childern: PropTypes.instanceOf(Object),
  onClick: PropTypes.func
};
Component.defaultProps = {
  name: '',
  small: false,
  large: false,
  medium: false,
  childern: {},
  onClick: () => {}
};
export default Component;
