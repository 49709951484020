import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Typography, Box } from '@material-ui/core';
import PlayStoreImage from '../../assets/images/playstore.png';
import useStyle from './styles';

const AuthLayout = ({ children }) => {
  const classes = useStyle();
  const recaptchaWrapperRef = React.useRef();
  useLayoutEffect(() => {
    window.recaptchaWrapperRef = recaptchaWrapperRef.current;
  }, [recaptchaWrapperRef.current]);
  return (
    <div className={classes.container}>
      <Box className={classes.boxContainer}>
        {children}
        {/* <div className={classes.infoContainer}>
          <Typography align="center" className={classes.infoTextTitle}>
            Download the app on your phone
          </Typography>
          <IconButton
            component="a"
            href="https://play.google.com/store/apps/details?id=app.inchat"
            className={classes.infoButton}
          >
            <img
              src={PlayStoreImage}
              alt="inChat"
              className={classes.infoMedia}
            />
          </IconButton>
        </div> */}
      </Box>
      <div ref={recaptchaWrapperRef}>
        <div id="recaptcha-container" />
      </div>
    </div>
  );
};

AuthLayout.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired
};

export default React.memo(AuthLayout);
