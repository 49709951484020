import React from "react";
import PropTypes from "prop-types";
import { useSelector, connect } from "react-redux";
import { compose } from "redux";
import { reduxForm, formValueSelector } from "redux-form";
import { Trans } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  Grid,
  CircularProgress,
  Button,
  MenuItem,
  IconButton,
} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import TextField from "../../inputs/textInput";
import TextSelect from "../../inputs/textSelect";
import ImageButton from "../../buttons/ImageButton";
import useStyles from "./styles";
import { UPDATE_USER_PROFILE } from "../../../graphql/profile";
import { useMutation } from "@apollo/react-hooks";

// import { ToastContainer, toast } from 'react-toastify';
// import { values } from "lodash";

const gender = [
  {
    value: "O",
    label: "gender_other",
  },
  {
    value: "M",
    label: "gender_male",
  },
  {
    value: "F",
    label: "gender_female",
  },
];
const EditProfileForm = ({
  submitting,
  initialValues,
  updateAvatar,
  handleSubmit,
  onSubmit,
  fullname,
  gender: usergender,
  avatar: profilePic,
  bio,
}) => {
  const classes = useStyles();
  const loading = useSelector((state) => state.auth.profileLoading);
  console.log(loading, "==============================loaddibgfd");
  const userID = useSelector(
    (state) =>
      state && state.auth && state.auth.profile && state.auth.profile.guid
  );
  const [preview, setPreview] = React.useState(initialValues.avatar);
  const handleFile = (files) => {
    const file = files[0];
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = (value) => {
      setPreview(value.target.result);
      updateAvatar(file);
    };
  };

  const dispatch = useDispatch();
  const [updateuserprofile] = useMutation(UPDATE_USER_PROFILE);
  const loadings = useSelector((state) => state.auth);

  const handleUpdate = (event) => {
    event.preventDefault();

    const userProfileData = { gender: usergender, profilePic, fullname, bio };
    const errors = {};
    if (userProfileData && userProfileData.fullname.length > 3) {
      updateuserprofile({ variables: { userID, userProfileData } });

      dispatch({
        type: "user/UPDATE_USER_PROFILE",
        payload: {
          userID,
          userProfileData,
        },
      });
      alert("profile updated");
    } else {
      errors.fullname = "Name should be between 3 and 36 characters";
    }
  };

  return (
    <Grid item xs={12} md={12}>
      <form onSubmit={handleUpdate}>
        <Grid item xs={12} md={12}>
          <div className={classes.avatarContainer}>
            <ImageButton handleFiles={handleFile} accept="image/*">
              <Avatar src={preview} classes={{ root: classes.avatar }} />
              <div className={classes.iconContainer}>
                <IconButton
                  color="primary"
                  aria-label="Upload picture"
                  component="span"
                >
                  <PhotoCamera fontSize="large" />
                </IconButton>
              </div>
            </ImageButton>
          </div>
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            name="fullname"
            label={<Trans>full_name</Trans>}
            variant="outlined"
            margin="normal"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextSelect
            label={<Trans>gender</Trans>}
            name="gender"
            variant="outlined"
            fullWidth
          >
            {gender?.map((option) => (
              <MenuItem key={option.label} value={option.value}>
                <Trans>{option.label}</Trans>
              </MenuItem>
            ))}
          </TextSelect>
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            name="bio"
            label="Something about you"
            variant="outlined"
            margin="normal"
            fullWidth
            rows="4"
            rowsMax="4"
            multiline
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <div className={classes.buttonContainer}>
            <Button
              variant="contained"
              type="submit"
              size="large"
              color="primary"
              // disabled={submitting || loading}
              classes={{ root: classes.button }}
            >
              <Trans>save</Trans>
            </Button>
            {/* {(submitting || loading) && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )} */}
          </div>
        </Grid>
      </form>
    </Grid>
  );
};

EditProfileForm.propTypes = {
  submitting: PropTypes.bool,
  initialValues: PropTypes.instanceOf(Object),
  handleSubmit: PropTypes.func,
  onSubmit: PropTypes.func,
  updateAvatar: PropTypes.func,
};

EditProfileForm.defaultProps = {
  submitting: false,
  initialValues: {},
  handleSubmit: () => {},
  onSubmit: () => {},
  updateAvatar: () => {},
};

const validate = (values) => {
  const errors = {};
  if (!values.fullname) {
    errors.fullname = "Name should be between 3 and 36 characters";
  } else if (
    values.fullname &&
    (values.fullname.length <= 3 || values.fullname.length >= 36)
  ) {
    errors.fullname = "Name should be between 3 and 36 characters";
  }
  if (!values.bio) {
    errors.bio = "Required";
  } else if (values.bio && values.bio.length >= 1000) {
    errors.bio = "Must be 1000 characters or less";
  } else if (values.bio && values.bio.length <= 3) {
    errors.bio = "Must be 3 characters or more";
  }
  if (!values.gender) {
    errors.gender = "select_gender";
  }
  return errors;
};
// export default reduxForm({
//   form: 'updateProfileForm',
//   validate
// })(EditProfileForm);

const selector = formValueSelector("updateProfileForm");

export default compose(
  connect((state) => {
    const { fullname, gender, bio, avatar } = selector(
      state,
      "fullname",
      "gender",
      "bio",
      "avatar"
    );
    return {
      fullname,
      gender,
      bio,
      avatar,
    };
  }),
  reduxForm({ form: "updateProfileForm", validate })
)(EditProfileForm);
