import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import {
  Typography, Grid, Button, CircularProgress
} from '@material-ui/core';
import TextInput from '../../inputs/textInput';
import useStyles from './styles';


const UpdateProfile = ({ submitting, dispatch, handleSubmit }) => {
  const classes = useStyles();
  const onSubmit = (values) => {
    dispatch({
      type: 'user/UPDATE_PROFILE',
      payload: values
    });
  };
  return (
    <div className={classes.updateProfileContainer}>
      <Grid container justify="center">
        <Grid item xs={12} md={12}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Typography
              variant="h4"
              component="h4"
              gutterBottom
              align="center"
              className={classes.titleLabel}
            >
              What do we call you?
            </Typography>
            <TextInput
              name="fullname"
              label="Your name"
              variant="outlined"
              fullWidth
            />
            <div className={classes.buttonContainer}>
              <Button
                type="submit"
                variant="outlined"
                color="primary"
                disabled={submitting}
              >
                Next
              </Button>
              {submitting && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </form>
        </Grid>
      </Grid>
    </div>
  );
};

UpdateProfile.propTypes = {
  submitting: PropTypes.bool,
  dispatch: PropTypes.func,
  handleSubmit: PropTypes.func,
};

UpdateProfile.defaultProps = {
  submitting: false,
  dispatch: () => {},
  handleSubmit: () => {},
};

const validate = (values) => {
  const errors = {};
  if (!values.fullname) {
    errors.fullname = 'Required';
  } else if (
    values.fullname
    && (values.fullname.trim().length < 3 || values.fullname.trim().length > 36)
  ) {
    errors.fullname = 'Name should be between 3 and 36 characters';
  }
  return errors;
};

export default reduxForm({
  form: 'updateProfileForm',
  validate
})(memo(UpdateProfile));
