import { createBrowserHistory } from 'history';
import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import { persistState } from 'redux-devtools';
import rootReducer from '../shared/reducers';
import DevTools from '../root/DevTools';
import rootSaga from '../sagas';

export const history = createBrowserHistory();
const routeMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();

const middleware = [thunkMiddleware, sagaMiddleware, routeMiddleware];

const enhancer = [
  DevTools.instrument(),
  persistState(window.location.href.match(/[?&]debug_session=([^&#]+)\b/)),
];

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancer);

export default (function configureStore(initialState) {
  const store = createStore(rootReducer(history), initialState, composedEnhancers);
  sagaMiddleware.run(rootSaga);
  return { store, history };
}());
