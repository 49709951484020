/* eslint-disable jsx-a11y/label-has-for */
import React, { memo, Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles';

const ImageButton = ({
  multiple, accept, disabled, children, handleFiles
}) => {
  const classes = useStyles();
  const handleChange = useCallback(
    (event) => {
      handleFiles(event.currentTarget.files);
    },
    [handleFiles]
  );
  return (
    <Fragment>
      <input
        accept={accept}
        className={classes.input}
        multiple={multiple}
        disabled={disabled}
        onChange={handleChange}
        id="icon-button-file"
        type="file"
      />
      <label htmlFor="icon-button-file">{children}</label>
    </Fragment>
  );
};

ImageButton.propTypes = {
  children: PropTypes.instanceOf(Object),
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  accept: PropTypes.string,
  handleFiles: PropTypes.func
};

ImageButton.defaultProps = {
  children: {},
  multiple: false,
  disabled: false,
  accept: 'image/*,video/*',
  handleFiles: () => {}
};

export default memo(ImageButton);
