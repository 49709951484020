import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import ErrorBoundary from './ErrorBoundary';

const ErrorBoundaryRoute = ({ component: Component, ...rest }) => {
  const encloseInErrorBoundary = props => (
    <ErrorBoundary>
      <Component {...props} />
    </ErrorBoundary>
  );
  if (!Component) throw new Error(`A component needs to be specified for path ${rest.path}`);
  return <Route {...rest} render={encloseInErrorBoundary} />;
};

ErrorBoundaryRoute.propTypes = {
  component: PropTypes.instanceOf(Object).isRequired
};

export default ErrorBoundaryRoute;
