import { all, takeEvery, put } from 'redux-saga/effects';
import actions from '../shared/actions/notification';

export function* SHOW_NOTIFICATION({ payload }) {
  yield put({
    type: 'notification/SHOW_NOTIFICATION',
    payload
  });
}

export function* DISMISS_NOTIFICATION({ payload }) {
  yield put({
    type: 'notification/DISMISS_NOTIFICATION',
    payload
  });
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.SET_SHOW, SHOW_NOTIFICATION),
    takeEvery(actions.SET_DISMISS, DISMISS_NOTIFICATION)
  ]);
}
