import classname from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Snackbar,
  SnackbarContent,
  Button,
  IconButton,
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import useStyles from './styles';

const NotificationIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

const NotificationContentWrapper = ({
  message,
  onClose,
  variant,
  className,
  ...restProps
}) => {
  const classes = useStyles();
  const Icon = NotificationIcon[variant];
  return (
    <SnackbarContent
      className={classname(classes[variant], className)}
      aria-describedby="client-notification"
      message={(
        <span id="client-notification" className={classes.message}>
          <Icon className={classname(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      )}
      action={[
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>
      ]}
      {...restProps}
    />
  );
};

NotificationContentWrapper.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired
};

NotificationContentWrapper.defaultProps = {
  className: '',
  message: '',
  onClose: () => {}
};

const NotificationProvider = React.memo(
  ({ notification, notificationProps, dispatch }) => {
    const [state, setState] = React.useReducer(
      (reducerState, newReducerState) => ({
        ...reducerState,
        ...newReducerState
      }),
      {
        open: false,
        message: null,
        action: null,
        variant: null,
        handleAction: () => {}
      }
    );
    const handleProcessQueue = React.useCallback(() => {
      if (notification) {
        const {
          message, action, handleAction, variant
        } = notification;
        setState({
          open: true,
          message,
          action,
          variant,
          handleAction: handleAction || (() => {})
        });
        dispatch({
          type: 'notification/SET_DISMISS',
          payload: notification
        });
      }
    }, [notification, dispatch]);

    const {
      open, message, action, variant
    } = state;

    const handleClose = (event, reason) => {
      if (reason === 'clickaway') return false;
      setState({ open: false, handleAction: null });
      return false;
    };

    const handleExited = () => {
      handleProcessQueue();
    };

    const handleClick = () => {
      handleClose();
      if (state.handleAction) state.handleAction();
    };

    React.useEffect(() => {
      if (notification) {
        // eslint-disable-next-line
        if (open) {
          setState({ open: false });
        } else {
          handleProcessQueue();
        }
      }
    }, [notification, handleProcessQueue]);

    return (
      <Snackbar
        {...notificationProps}
        open={open}
        message={message || ''}
        action={
          action && (
            <Button color="secondary" size="small" onClick={handleClick}>
              {action}
            </Button>
          )
        }
        onClose={handleClose}
        onExited={handleExited}
      >
        {variant && (
          <NotificationContentWrapper
            onClose={handleClick}
            variant={variant}
            message={message}
          />
        )}
      </Snackbar>
    );
  }
);

NotificationProvider.propTypes = {
  notification: PropTypes.instanceOf(Object),
  notificationProps: PropTypes.instanceOf(Object),
  dispatch: PropTypes.func
};

NotificationProvider.defaultProps = {
  notification: {},
  notificationProps: {},
  dispatch: () => {}
};

const mapStateToProps = state => ({
  notification: state.notification.queue[0] || null
});

const mapDispatchToProps = dispatch => ({
  dispatch
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationProvider);
