import React, { memo, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import { Typography } from '@material-ui/core';
import useStyles from './styles';
import { GET_PHONE_NUMBER } from '../../../graphql';

const PhoneNumber = ({ phoneNumberRef }) => {
  const classes = useStyles();
  const { data: { phoneNumber } } = useQuery(GET_PHONE_NUMBER);
  useLayoutEffect(() => {
    // eslint-disable-next-line no-param-reassign
    phoneNumberRef.current = phoneNumber;
  }, [phoneNumber]);
  return (
    <div className={classes.titleContainer}>
      <Typography
        variant="h4"
        component="h4"
        className={classes.title}
        align="center"
      >
        Verification code
      </Typography>
      <Typography variant="body1" component="p" align="center">
        {`You will receive a sms on ${phoneNumber} with your verification code`}
      </Typography>
    </div>
  );
};

PhoneNumber.propTypes = {
  phoneNumberRef: PropTypes.instanceOf(Object)
};

PhoneNumber.defaultProps = {
  phoneNumberRef: {}
};

export default memo(PhoneNumber);
