import React from 'react';
import loaderImage from '../../assets/images/loader.png';

const PageLoader = () => (
  <div className="full-page-loader">
    <div className="loader">
      <img src={loaderImage} alt="tejiMandi" />
    </div>
  </div>
);

PageLoader.propTypes = {};

export default PageLoader;
