import gql from 'graphql-tag';

export const GET_ACTIVE_MODAL = gql`
  {
    activeModalValue @client {
      ID
      communityID
      statusMessage
    }
    activeModalName @client
  }
`;

export const UPDATE_MODAL_NAME = gql`
  mutation updateModalName($activeModalName: String!) {
    updateModalName(activeModalName: $activeModalName) @client
  }
`;

export const UPDATE_MODAL_VALUE = gql`
  mutation updateModalValue($activeModalValue: ActiveModalValue!) {
    updateModalValue(activeModalValue: $activeModalValue) @client
  }
`;
