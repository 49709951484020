import React, { Fragment } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import AuthLogin from './auth';
// import TermConditionStock from './termStock';
import VerificationCode from './verificationCode';
import Boarding from './boarding';
import DeletePost from './deletePost';
// import ReportPost from './reportPost';
// import ReportMessage from './reportMessage';
// import NewPost from './newPost';
// import PostPreview from './postPreview';
// import PlaceOrder from './placeOrder';
// import PickStock from './pickStock';
// import Brokers from './brokers';
// import Compose from './compose';
// import Account from './account';
// import CreateGroup from './createGroup';
// import EditGroup from './editGroup';
import EditProfile from './editProfile';
// import GlobalSearch from './globalSearch';
// import SearchStock from './searchStock';
// import DeleteConversation from './deleteConversation';
// import LeaveConversation from './leaveConversation';
import { GET_ACTIVE_MODAL, UPDATE_MODAL_NAME } from '../../graphql';

const DialogProvider = () => {
  const {
    data: { activeModalName, activeModalValue }
  } = useQuery(GET_ACTIVE_MODAL);
  const [updateModalName] = useMutation(UPDATE_MODAL_NAME);

  // const handleOpen = modalName => () => {
  //   updateModalName({ variables: { activeModalName: modalName } });
  // };

  const handleClose = () => {
    updateModalName({ variables: { activeModalName: '' } });
  };

  return (
    <Fragment>
      <AuthLogin
        activeModal={activeModalName.includes('authLogin') > 0}
        onClose={handleClose}
      />
      <VerificationCode
        activeModal={activeModalName.includes('verificationCode') > 0}
        onClose={handleClose}
      />
      <Boarding
        activeModal={activeModalName.includes('onboarding') > 0}
        onClose={handleClose}
      />
      <DeletePost
        activeModal={activeModalName.includes('deletePost') > 0}
        selectedValue={activeModalValue}
        onClose={handleClose}
      />
      <EditProfile
        activeModal={activeModalName.includes('editProfile') > 0}
        onClose={handleClose}
      />
      {/* <ReportPost
        activeModal={activeModalName.includes('reportPost') > 0}
        selectedValue={activeModalValue}
        onClose={handleClose}
      />
      <ReportMessage
        activeModal={activeModalName.includes('reportMessage') > 0}
        onClose={handleClose}
      />
      <NewPost
        activeModal={activeModalName.includes('newPost') > 0}
        onClose={handleClose}
      />
      <PostPreview
        activeModal={activeModalName.includes('postPreview') > 0}
        onClose={handleClose}
      />
      <PlaceOrder
        activeModal={activeModalName.includes('placeOrder') > 0}
        onClose={handleClose}
      />
      <PickStock
        activeModal={activeModalName.includes('pickStockInfo') > 0}
        onClose={handleClose}
      />
      <Brokers
        activeModal={activeModalName.includes('brokers') > 0}
        onClose={handleClose}
      />
      <TermConditionStock
        activeModal={activeModalName.includes('termConditionStock') > 0}
        onClose={handleClose}
      />
      <GlobalSearch
        activeModal={activeModalName.includes('globalSearch') > 0}
        onClose={handleClose}
      />
      <CreateGroup
        activeModal={activeModalName.includes('createGroup') > 0}
        onClose={handleClose}
      />
      <EditGroup
        activeModal={activeModalName.includes('editGroup') > 0}
        onClose={handleClose}
      />
      
      <Compose
        activeModal={activeModalName.includes('compose') > 0}
        onClose={handleClose}
      />
      <Account
        activeModal={activeModalName.includes('accountProfile') > 0}
        onClose={handleClose}
      />
      <SearchStock
        activeModal={activeModalName.includes('searchStock') > 0}
        onClose={handleClose}
      />
      <DeleteConversation
        activeModal={activeModalName.includes('deleteConversation') > 0}
        onClose={handleClose}
      />
      <LeaveConversation
        activeModal={activeModalName.includes('leaveConversation') > 0}
        onClose={handleClose}
      /> */}
    </Fragment>
  );
};

export default React.memo(DialogProvider);
