export const getMutatedData = (graphQlResponseData) => {
  
  const { whoAmI } = graphQlResponseData;

  const {
    ID: guid,
    userType: type,
    username,
    profilePic: avatarURL,
    followingTotal: count_following,
    followersTotal: count_followers,
    insightsTotal,
    followStatus: follow,
    followsMe,
    backFollowStatus: reversefollow,
    fullname,
    insightsLikedTotal,
    groupsAdmin,
    verified,
    isSubscriber,
    subscriberType,
    nextPayment,
    identityVerified,
    kraVerified,
    ckycVerified,
    accessRights,
    primaryEmail: email,
    primaryPhone: mobilephone,
    bio,
    gender,
    ageRange: age_range,
    expLevel: exp_level,
    refcode,
    settings,
  } = whoAmI;

  const settingsData = settings.map((val) => {
    return {
      guid: val.guid,
      name: val.name,
      value: val.value,
      userGuid: guid,
    };
  });

  const newResponseData = {
    success: true,
    data: {
      guid,
      type,
      username,
      avatarURL,
      count_following,
      count_followers,
      count_posts: 0,
      follow,
      followsMe,
      reversefollow,
      fullname,
      reported: null,
      insightsTotal,
      insightsLikedTotal,
      groupsAdmin,
      verified,
      isSubscriber,
      subscriberType,
      nextPayment,
      activeOrderId: null,
      activeSubscriptionId: null,
      subscriptionCancelAt: null,
      identityVerified,
      kraVerified,
      ckycVerified,
      panType: null,
      signatureSet: null,
      accessRights: accessRights,
      email,
      mobilephone,
      settings: settingsData,
      profileFields: {
        bio,
        fullname,
        gender,
        age_range,
        exp_level,
        verified: identityVerified === true ? 1 : 0,
        id_sign: null,
        state: null,
        aadhaar: null,
        refcode,
        verified_name: null,
        ckycZip: null,
        ckycZipAt: null,
        ckycStatus: null,
        refcode_backup: null,
        primaryEmail: email,
        primaryPhone: mobilephone,
      },
      location: null,
      created_at: null,
      updated_at: null,
    },
  };

  console.log(newResponseData);

  return newResponseData;
};

//getMutatedData(graphQlData);
