import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  contentContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    '&:first-child': {
      paddingTop: 0,
    },
    padding: 0
  },
  updateProfileContainer: {
    padding: 50,
    [theme.breakpoints.down('xs')]: {
      padding: 10,
    },
  },
  titleLabel: {
    marginTop: 10,
    fontSize: 22,
  },
  subtitleLabel: {

  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -7,
    marginLeft: -7,
  },
  communityContainer: {
    padding: '10px 0',
    width: '100%',
    minHeight: '70vh',
    [theme.breakpoints.down('xs')]: {
      padding: '5px 0',
    },
  },
  primary: {
    fontSize: 18,
    wordBreak: 'break-all',
    fontWeight: '700',
    lineHeight: '1',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
  },
  subheader: {
    textTransform: 'uppercase',
    color: '#d295bf',
  },
  avatar: {
    minWidth: 80
  },
  scrollContainer: {
    width: '100%',
    maxHeight: '60vh',
    overflow: 'auto',
    [theme.breakpoints.down('xs')]: {
      height: 'calc(100vh - 35vh)',
    },
  },
}));
