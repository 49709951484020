import actions from './actions';

const INITIAL_STATE = {
  selectedScreen: 'portfolio'
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actions.UPDATE:
      return { ...state, selectedScreen: action.payload };
    default:
      return state;
  }
};
