import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useStyle from './styles';

const Header = React.lazy(() => import('../../shared/header'));

const ProfileLayout = ({ children }) => {
  const classes = useStyle();
  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <div className={classes.root}>
      {!mobileScreen && <Header />}
      <div className={classes.container}>
        <main className={classes.contentContainer}>
          <div className={classes.content}>
            {!mobileScreen && <div className={classes.topToolbar} />}
            <div className={classes.contentWrapper}>
              <div className={classes.childrenContainer}>
                {children}
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

ProfileLayout.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired
};

export default React.memo(ProfileLayout);
