import React, { forwardRef, memo } from 'react';
import { connect } from 'react-redux';
import { useMutation } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Slide,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CloseIcon from '@material-ui/icons/Close';
import AuthForm from './authForm';
import useStyles from './styles';
import { UPDATE_PHONE_NUMBER, UPDATE_MODAL_NAME } from '../../../graphql';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const Auth = ({ activeModal, dispatch, onClose }) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [updatePhoneNumber] = useMutation(UPDATE_PHONE_NUMBER);
  const [updateModalName] = useMutation(UPDATE_MODAL_NAME);
  let props = {
    fullScreen,
    fullWidth: true,
    open: activeModal,
  };
  if (fullScreen) {
    props = Object.assign({}, props, {
      TransitionComponent: Transition,
    });
  }
  const handleSubmit = (values) => {
    const phoneNumber = `+${values.countryCode}${values.phoneNumber}`;
    updatePhoneNumber({ variables: { phoneNumber } });
    updateModalName({ variables: { activeModalName: 'verificationCode' } });
    dispatch({
      type: 'user/SET_STATE',
      payload: {
        error: false,
      },
    });
    dispatch({
      type: 'user/PHONE_SEND_LOGIN',
      payload: {
        phoneNumber,
      },
    });
  };
  return (
    <Dialog {...props}>
      <DialogTitle
        disableTypography
        component="div"
        classes={{ root: classes.title }}
      >
        <Typography variant="h6">Login</Typography>
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <div className={classes.paragraphContainer}>
          <Typography
            variant="h6"
            className={classes.paragraph}
            component="span"
          >
            Sign up to join our community of investors and get notified on top
            stock picks and insights
          </Typography>
        </div>
        <AuthForm onSubmit={handleSubmit} />
      </DialogContent>
    </Dialog>
  );
};

Auth.propTypes = {
  activeModal: PropTypes.bool,
  onClose: PropTypes.func,
  dispatch: PropTypes.func,
};

Auth.defaultProps = {
  activeModal: false,
  onClose: () => {},
  dispatch: () => {},
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

export default connect(null, mapDispatchToProps)(memo(Auth));
