import { connect } from 'react-redux';
import React, { forwardRef, memo } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, Slide } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import UpdateProfile from './updateProfile';
import Communities from './communities';
import useStyles from './styles';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const Boarding = memo(({ activeModal, onClose, dispatch }) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  let props = {
    fullScreen,
    fullWidth: true,
    open: activeModal
  };
  if (fullScreen) {
    props = Object.assign({}, props, {
      TransitionComponent: Transition
    });
  }
  return (
    <Dialog {...props}>
      <DialogContent className={classes.contentContainer}>
        {/* <UpdateProfile dispatch={dispatch} /> */}
        <Communities />
      </DialogContent>
    </Dialog>
  );
});

Boarding.propTypes = {
  activeModal: PropTypes.bool,
  onClose: PropTypes.func,
  dispatch: PropTypes.func,
};

Boarding.defaultProps = {
  activeModal: false,
  onClose: () => {},
  dispatch: () => {}
};

const mapDispatchToProps = dispatch => ({
  dispatch
});

export default connect(null, mapDispatchToProps)(Boarding);
