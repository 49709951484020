import classname from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { Field } from 'redux-form';

const useStyle = makeStyles({
  root: {
    '& $notchedOutline': {
    },
  },
  notchedOutline: {},
  roundBorder: {
    borderRadius: 20,
    borderWidth: 2
  }
});

const TextInput = ({
  input,
  meta: { touched, error },
  roundBorder,
  ...props
}) => {
  const classes = useStyle();
  const hasError = (touched && error && true) || false;
  return (
    <TextField
      error={hasError}
      helperText={touched && error}
      className="roundBorder"
      InputProps={{
        classes: {
          root: classname(classes.root, {
            [classes.roundBorder]: roundBorder
          }),
          notchedOutline: classname(classes.notchedOutline, {
            [classes.roundBorder]: roundBorder
          })
        },
      }}
      {...input}
      {...props}
    />
  );
};

TextInput.propTypes = {
  input: PropTypes.instanceOf(Object),
  meta: PropTypes.instanceOf(Object),
  roundBorder: PropTypes.bool,
};

TextInput.defaultProps = {
  input: {},
  meta: {},
  roundBorder: false
};

const FieldInput = props => (
  <Field {...props} component={TextInput} />
);

export default FieldInput;
