import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { ApolloProvider } from '@apollo/react-hooks';
import { NotificationProvider } from '../shared/notification';
import ErrorBoundary from '../shared/error/ErrorBoundary';
import { DialogProvider } from '../shared/dialogs';
import defaultTheme from '../theme';
import Routes from '../routes';

const Root = ({ store, client }) => (
  <ErrorBoundary>
    <Provider store={store.store}>
      <NotificationProvider
        notificationProps={{
          autoHideDuration: 3500,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          }
        }}
      />
      <ApolloProvider client={client}>
        <ThemeProvider theme={defaultTheme}>
          <CssBaseline />
          <ErrorBoundary>
            <Routes history={store.history} />
          </ErrorBoundary>
          <ErrorBoundary>
            <DialogProvider />
          </ErrorBoundary>
        </ThemeProvider>
      </ApolloProvider>
    </Provider>
  </ErrorBoundary>
);

Root.propTypes = {
  store: PropTypes.instanceOf(Object),
  client: PropTypes.instanceOf(Object),
  history: PropTypes.instanceOf(Object)
};

Root.defaultProps = {
  store: {},
  client: {},
  history: {}
};

export default Root;
