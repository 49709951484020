import actions from '../actions/notification';

const INITIAL_STATE = {
  queue: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    case actions.SHOW_NOTIFICATION:
      return { queue: [...state.queue, { id: Date.now(), ...action.payload }] };
    case actions.DISMISS_NOTIFICATION:
      return {
        queue: state.queue.filter(
          notification => notification.id !== action.payload.id
        )
      };
    default:
      return state;
  }
};
