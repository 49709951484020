import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import useStyle from './styles';

const StaticLayout = ({ children }) => {
  const classes = useStyle();
  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <main className={classes.contentContainer}>
          <div className={classes.content}>
            <div className={classes.contentWrapper}>
              <div className={classes.childrenContainer}>
                {children}
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

StaticLayout.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired
};

export default React.memo(withRouter(StaticLayout));
