import gql from 'graphql-tag';

export const GET_POST_PREVIEW_VALUES = gql`
  {
    postPreviewValue @client {
      message
      messageType
      messageDescription
      tmStatus
      options
    }
  }
`;

export const UPDATE_POST_PREVIEW_VALUE = gql`
  mutation updatePreviewValue($activePreviewValue: activePreviewValue!) {
    updatePreviewValue(activePreviewValue: $activePreviewValue) @client
  }
`;
