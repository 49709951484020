import classname from 'classnames';
import React, { forwardRef, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { Trans } from 'react-i18next';
import { useApolloClient } from '@apollo/react-hooks';
import {
  Dialog, DialogContent, IconButton, Slide
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { BackArrowIcon } from '../../../assets/icons';
import EditProfileForm from './form';
import useStyles from './styles';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const EditProfile = ({ activeModal, onClose }) => {
  const classes = useStyles();
  const client = useApolloClient();
  const theme = useTheme();
  const dispatch = useDispatch();
  const profile = useSelector(state => state.auth.profile);
  const { profileFields, fullname, avatarURL } = profile;
  const { gender, bio } = profileFields || {};
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const initialValues = {
    fullname,
    gender,
    bio,
    avatar: avatarURL
  };
  let props = {
    fullScreen,
    fullWidth: true,
    open: activeModal
  };
  if (fullScreen) {
    props = Object.assign({}, props, {
      TransitionComponent: Transition
    });
  }
  const handleSubmit = (values) => {
    dispatch({
      type: 'user/UPDATE_USER_PROFILE',
      payload: { values }
    });
  };
  const updateAvatar = (file) => {
    dispatch({
      type: 'user/UPDATE_USER_AVATAR',
      payload: { file }
    });
  };
  const handleClick = () => {
    onClose();
    dispatch(push('/auth/logout'));
  };
  const handleUpdateProfile= () =>{
    console.log(profileFields, fullname, avatarURL,'DDDDDDDDDD')

  }

  return (
    <Dialog {...props}>
      <DialogContent className={classes.container}>
        <div className={classes.backContainer}>
          <IconButton
            aria-label="Close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <BackArrowIcon fontSize="small" />
          </IconButton>
          {!fullScreen && (
          <IconButton
            aria-label="Logout"
            onClick={handleClick}
            className={classname(classes.closeButton, classes.logoutButton)}
          >
            <ExitToAppIcon />
          </IconButton>
          )}
        </div>
        <EditProfileForm
          initialValues={initialValues}
          onSubmit={handleSubmit}
          updateAvatar={updateAvatar}
        />
      </DialogContent>
    </Dialog>
  );
};

EditProfile.propTypes = {
  activeModal: PropTypes.bool,
  onClose: PropTypes.func
};

EditProfile.defaultProps = {
  activeModal: false,
  onClose: () => {}
};

export default memo(EditProfile);
