import { storage } from '../config';
import axios from '../config/axios';
import defaultAxios from "../config/defaultAxios";

export function getCompressedImage(file) {
  let url =
    window.location.host === "adminstaging.tejimandi.com" || "localhost:3000" 
      ? "https://us-central1-tejimandiprod.cloudfunctions.net/imageUpload"
      : "https://us-central1-tejimandibeta.cloudfunctions.net/imageUpload";
  let formData = new FormData();
  console.log('swapnilhost',window.location.host);
  formData.append("fileToUpload", file);
  let config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  return defaultAxios
    .post(
      url,
      formData,
      config
    )
    .then((response) => ({
      success: true,
      data: response.data.data.compressed
    }))
    .catch((error) => {
      console.log(error);
    });
} 

export function uploadFile(pathOrRef, file, metadata) {
  const ref = storage.ref(pathOrRef).child(file.name);
  const task = ref.put(file, metadata);
  return new Promise((resolve, reject) => {
    task.on('state_changed',
      (snapshot) => {
        const pct = (snapshot.bytesTransferred * 100) / snapshot.totalBytes;
        console.log(`${pct}%`);
      },
      error => reject(error),
      () => {
        ref.getDownloadURL().then((response) => {
          resolve({
            storageName: file.name,
            storageFile: response,
          });
        });
      });
  });
}

export function getDownloadURL(pathOrRef) {
  const ref = storage.ref(pathOrRef);
  return ref.getDownloadURL();
}


export function updateStorageImageFile(filename, fileType) {
  let endPointURL = `create-photo-from-file&filename=images/${filename}`;
  console.log(fileType, 'fileType');

  if (fileType === 'video') {
    endPointURL = `encode-video&filename=videos/${filename}`;
  }
  return axios(5)
    .get(endPointURL)
    .then(response => ({
      success: true,
      user: response.data.data
    }))
    .catch(error => ({
      success: false,
      ...error.response.data.data
    }));
}

export function getStorageRef(pathOrRef) {
  const ref = storage.ref(pathOrRef);
  return ref;
}

export async function getImages(pathOrRef, limit, token) {
  const storageRef = getStorageRef(pathOrRef);
  // const listRef = storageRef.child('files/uid');
  // Fetch the first page of 100.
  const payload = { maxResults: limit };
  if (token) {
    payload.pageToken = token;
  }
  const page = await storageRef.list(payload);
  return page;
}

export default {
  uploadFile,
  getImages,
  getDownloadURL,
  updateStorageImageFile
};
