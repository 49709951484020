import {
  all, takeEvery, put, takeLatest
} from 'redux-saga/effects';
import actions from '../shared/actions/message';

export function* INIT_SOCKET_CONNECTION({ payload }) {
  yield put({
    type: 'message/SET_STATE',
    payload,
  });
}

export function* GET_MESSAGE_VALUES({ payload }) {
  yield put({
    type: 'message/SET_STATE',
    payload: {
      messages: payload,
    },
  });
}

export function* ADD_MESSAGE_VALUES({ payload }) {
  yield put({
    type: 'message/SET_STATE',
    payload: {
      messages: payload,
    },
  });
}

export function* ADD_MESSAGE_VALUE({ payload }) {
  yield put({
    type: 'message/SET_MESSAGE_VALUE',
    payload,
  });
}

export function* UPDATE_MESSAGE_ACTION({ payload }) {
  yield put({
    type: 'message/SET_STATE',
    payload,
  });
}

export function* UPDATE_USER_CALLBACK({ payload }) {
  yield put({
    type: 'message/SET_STATE',
    payload: {
      callback: payload,
    },
  });
}

export function* SET_EDIT_MESSAGE({ payload }) {
  yield put({
    type: 'message/SET_STATE',
    payload,
  });
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.INIT_SOCKET_CONNECTION, INIT_SOCKET_CONNECTION),
    takeLatest(actions.SET_EDIT_MESSAGE, SET_EDIT_MESSAGE),
    takeEvery(actions.GET_MESSAGE_VALUES, GET_MESSAGE_VALUES),
    takeEvery(actions.ADD_MESSAGE_VALUE, ADD_MESSAGE_VALUE),
    takeEvery(actions.UPDATE_MESSAGE_ACTION, UPDATE_MESSAGE_ACTION),
    takeLatest(actions.UPDATE_USER_CALLBACK, UPDATE_USER_CALLBACK),
  ]);
}
